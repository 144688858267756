.splashPage {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  height: 100vh;

  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  text-align: center;
  font-size: 48px;
  @media(max-width: 768px) {
      font-size: 32px;
  }

  &.fadeOut {
      opacity: 0;
      transition: opacity 1s;
  }

  .splash-container {
    margin: 13% 0 4% 0;

    @media(max-width: 1200px) {
      margin: 7% 0 4% 0;
    }
  }
}

.beginButton {
  &:focus {
    outline: none;
  }
  font-family: Gaegu, Comic Sans, sans-serif;
  color: #F95757;
  margin: 4% 0 6% 0;

  @media(max-width: 1200px) {
    margin: 3% 0 3% 0;
  }

  @media(max-width: 768px) {
    margin: 4% 0 15% 0;
  }
}

.laurels {
  width: 80%;
  margin: 0 auto;

  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 20px 0;

  @media(max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media(max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media(max-width: 768px) {
    width: 90%;
  }

  img {
    height: 100px;
    margin: auto;

    @media(max-width: 768px) {
      height: 90px;

      //Used for when there were 5 laurels to force 2-1-2 layout
      // &:nth-child(3) {
      //   margin: 0 220px 20px 220px;
      // }
    }
  }
}
