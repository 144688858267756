.image {
    position: absolute;
    // border: 1px solid blue;
    // filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.5));
    opacity: 0;
    // display: none;
    transition: left 500ms ease-out, top 500ms ease-out, filter 100ms;
    filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.2));
    -webkit-transform: translateZ(0);
    will-change: left, top, transform, opacity, filter;
    &.in {
        // display: block;
        opacity: 1;
        transition: opacity 500ms, left 500ms ease-out, top 500ms ease-out, filter 100ms;
    }
    &.active {
        z-index: 1;
        filter: drop-shadow(10px 10px 10px rgba(0,0,0,1));
    }
    &.clickable {
        cursor: pointer;
        opacity: 1;
        &:not(:hover):not(.visited) {
            animation: wiggle 1.5s alternate infinite ease-in;
        }

        &:hover {
            z-index: 2;
            filter: drop-shadow(15px 15px 15px rgba(0,0,0,1));
        }
    }
    &.visited {
        opacity: 1;
        transition: opacity 500ms, left 500ms ease-out, top 500ms ease-out, filter 0ms;
        &:hover {
            transition: opacity 500ms, left 500ms ease-out, top 500ms ease-out, filter 100ms;
        }
        &:not(.clickable) {
            z-index: 0;
            // filter: contrast(20%) brightness(150%);
            // transition: opacity 500ms, left 500ms ease-out, top 500ms ease-out, filter 0;
        }
    }

    @keyframes wiggle {
        0%   {transform: scale(.95);}
        100% {transform: scale(1);}
    }
}
