.subtitles {
    font-size: 36px;
    width: 100%;
    max-width: 800px;
    position: absolute;
    left: 0;
    right: 0;
    top: 60px;
    @media(max-width: 1050px) {
        top: 75px;
    }
    margin: auto;
    @media(max-width:768px) {
        font-size: 24px;
    }
}