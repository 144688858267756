.shuffledPiecesWrapper {
    width: 820px;
    z-index: -1;
    max-width: 100%;
    height: 620px;
    position: absolute;
    top: 205px;
    bottom: 0;
    @media(max-height: 768px) {
        bottom: auto;
    }
    left: 0;
    right: 0;
    margin: auto;
    transform-origin: left;
}

.familyPhoto {
    position: absolute;
    opacity: 0;
    transition: 1500ms opacity;
    &.visible {
        opacity: 1;
    }
}

.stickFigure {
    position: absolute;
    animation: draw-in-stick-figures 5s both;
}

@keyframes draw-in-stick-figures {
    0% {
       opacity: 0; 
    }
    100% {
        opacity: 1;
    }
}

.text {
    position: relative;
    top: 50px;
    font-size: 36px;
    max-width: 992px;
    margin: auto;
}