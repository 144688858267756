.canvas {
    position: absolute;
    top: 205px;
    bottom: 0;
    @media(max-height: 768px) {
        bottom: auto;
    }
    left: 0;
    right: 0;
    margin: auto;
    max-width: 100%;
}